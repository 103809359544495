import React, { useEffect, useState } from "react"
import Navbar from "../navbar"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./style.scss"
import constants from "../../constants/contants"

import { Link } from "gatsby"

const data = [
  {
    heading: "July 2024 Issue",
    hightlights: [
      "City of Lights , Stage of Dreams : Paris Olympics 2024",
      "The Dawn of a new Ace : Carlos Alcaraz",
      "Twist Turn and Triumph : Celebrating 50 years of the Iconic Rubic Cube ",
      "Youthquake  : inspiring stories of young achievers.",
      "Cell-ebrate Success : Mastering Biology with Ease !",
      // "NEP - An objective take",
      // "Pre-Boards: Trial or Practice ?",
      // "Chess: Siblings rule the World",
      // "International Baccalaureate - IB Manthan 2024 Curtain Raiser",
      // "Mistakes: Stepping Stone to Success!",
      // "A trip to Tiruvananthaputam: City of Palaces",
      // "DIY: Tear Off Calendar 2024 you can paste on any of your notebooks !",
      // "⁠Sections on Amazing Facts",
      // "Leap into 2024",
      // "Boards- Face them fearlessly",
      // "Ram Lalla is home",
      // "Equestrian Focus and Polo- The Royal sport",
      // "Careers we don't know of yet !",
      // "Parenting tips by Padmabhushan Dr. Shayama Chona",
    ],

    // coverLink: "https://school-live.com/assets/images/2023/december.jpg",
    coverLink: constants.currentCoverLink,
  },
]

export default function Hero() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const [transformValue, setTransformValue] = useState(0)

  useEffect(() => {
    function handleScroll() {
      let yPos = window.scrollY

      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(function () {
        if (yPos < 100) {
          setTransformValue(yPos)
        }
      }, 0)
    }

    var timer = null
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className="hero">
      <Navbar />

      <img
        className="hero__bg"
        alt=""
        src="http://school-live.com/assets/images/hero-img-bg.png"
      ></img>
      <div className="hero__figures">
        <div
          className="hero__figures__small-el"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "1s",
          }}
        ></div>
        <div
          className="hero__figures__small-el2"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "2s",
          }}
        ></div>
        <div
          className="hero__figures__small-el3"
          style={{
            transform: `translateX(${transformValue}px)`,
            transition: "2.5s",
          }}
        ></div>
      </div>

      <div className="hero__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div key={`hero-slide-${i}`} className="hero__slide">
              <div className="hero__slide__content">
                <div className="hero__slide__text">
                  <h1 className="hero__slide__heading">{item.heading}</h1>
                  <h2>Hightlights</h2>
                  <ul>
                    {item.hightlights.map((item, i) => {
                      return <li key={`hightlight-item-${i}`}>{item}</li>
                    })}
                  </ul>
                  <div className="hero__slide__buttons">
                    <Link className="button-primary" to="/subscriptions">
                      Subscriptions
                    </Link>
                    <Link className="button-primary" to="/archives">
                      Explore Issues
                    </Link>
                    <br />
                    <Link
                      className="button-primary btn-membership"
                      to="/membership"
                    >
                      Elite School READ Club Membership
                    </Link>
                  </div>
                </div>
                <div className="hero__slide__cover">
                  <img alt={`${item.heading} Cover`} src={item.coverLink}></img>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
